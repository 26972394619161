<template>
  <div class="page catalog-page">
    <BreadcrumbsComponent :title="category.title" :links="links" />
    <div class="page__inner">
      <SectionComponent>
        <div class="catalog-page__inner">
          <div class="catalog-page__aside">
            <CatalogFiltersComponent
              :siblings="category.siblings && category.siblings.length ? category.siblings : category.childs"
            />
          </div>
          <div class="catalog-page__body">
            <ProductsListComponent v-if="showProducts" :items="products.data" />
            <PaginationComponent
              v-if="showProducts && Math.ceil(total / first) > 1"
              :page="page"
              :total="Math.ceil(total / first)"
              @change="paginate"
            />
            <template v-if="childs && childs.length">
              <ProductsListComponent
                v-for="(child, i) in childs"
                :key="i"
                :items="child.products"
                :title="child.title"
              />
            </template>
            <div v-else-if="!showProducts" class="empty-block">Прямо сейчас ничего не осталось!</div>
          </div>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import CatalogFiltersComponent from "./components/CatalogFiltersComponent.vue";
import ProductsListComponent from "components/ProductsListComponent.vue";
import CATALOG_PAGE from "@/graphql/pages/CatalogPage.graphql";
import PaginationComponent from "components/Pagination.vue";

async function load({ apollo, store, route, variables }) {
  store.state.loading = true;
  await apollo.defaultClient
    .query({
      query: CATALOG_PAGE,
      variables: {
        id: parseInt(route.params.id),
        ...variables,
      },
    })
    .then(({ data }) => {
      store.state.catalog_page.category = data.category || {};
      store.state.catalog_page.products = data.products_paginate || {};
      // дефолтные данные
      store.state.global.categories = data.categories || [];
      store.state.global.feedback = data.feedback || {};
      store.state.global.pages = data.pages || [];
    })
    .finally(() => {
      store.state.loading = false;
    });
}

export default {
  name: "CatalogPage",
  async asyncData({ apollo, store, route }) {
    let variables = {
      first: 9,
      page: 1,
    };
    await load({ apollo, store, route, variables });
  },
  data() {
    return {
      page: 1,
      first: 9,
    };
  },
  computed: {
    category() {
      return this.$store.state.catalog_page.category;
    },
    products() {
      return this.$store.state.catalog_page.products;
    },
    childs() {
      return this.category?.childs?.length
        ? this.category.childs.filter((i) => i.products && i.products.length)
        : [];
    },
    links() {
      let category = this.$store.state.catalog_page.category;
      if (category && category.parent) {
        return [
          { title: category.parent?.title, route: { name: "catalog", params: { id: category.parent.id } } },
        ];
      } else {
        return null;
      }
    },
    showProducts() {
      return this.products && this.products.data && this.products.data.length;
    },
    total() {
      return this.$store.state.catalog_page.products?.paginatorInfo.total;
    },
  },
  methods: {
    load,
    paginate(e) {
      window.scrollTo(0, 0);
      this.page = e;
      this.load({
        apollo: this.$apolloProvider,
        store: this.$store,
        route: this.$route,
        variables: { page: this.page, first: this.first },
      });
    },
  },
  metaInfo() {
    return {
      title: this.category.title || "Каталог",
    };
  },
  components: {
    PaginationComponent,
    ProductsListComponent,
    CatalogFiltersComponent,
    SectionComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.catalog-page {
  &__inner {
    display grid
    grid-template-columns minmax(303px, 303fr) 967fr
    grid-gap 30px
    +below(860px) {
      grid-template-columns 1fr
    }
  }

  &__aside {
    --top-offset: 20px
    align-self start
    max-height calc(100vh - var(--top-offset))
    display flex
    +above(861px) {
      position sticky
      top var(--top-offset)
    }
  }

  &__body {
    display flex
    flex-direction column
    gap: 50px
  }
}
</style>
