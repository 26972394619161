<template>
  <div class="filters-item" :class="{ 'filters-item--open': open }">
    <router-link
      :to="{ name: 'catalog', params: { id: data.id } }"
      type="button"
      class="filters-item__header"
    >
      <span class="filters-item__title">{{ data.title }}</span>
      <IconComponent name="arrow-up-2" v-if="childs && childs.length" />
    </router-link>
    <div class="filters-item__body" v-if="childs && childs.length && open">
      <a href="#" @click.prevent class="filters-item__body-link" v-for="(item, i) in childs" :key="i">
        {{ item.title }}
      </a>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "FiltersItemComponent",
  components: { IconComponent },
  props: {
    data: Object,
    open: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  computed: {
    childs() {
      return this.data?.childs?.length ? this.data.childs.filter((i) => i.products && i.products.length) : [];
    },
  },
};
</script>

<style lang="stylus">
.filters-item {
  flex-shrink 0
  display grid
  align-items start
  grid-gap 10px

  &--open &__header {
    background var(--gray)

    .icon {
      transform rotate(180deg)
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    text-align left
    gap 10px
    border none
    background none
    font-size 1.125em
    font-weight 700
    line-height 26px
    cursor pointer
    padding 10px 15px
    border-radius var(--main-radius)
    transition var(--transition)

    .icon {
      width 12px
      height 12px
      transform rotate(90deg)
    }

    &:hover {
      color var(--main)
      background var(--gray)

      //.icon svg path {
      //  fill var(--main)
      //  transition var(--transition)
      //}
    }
  }

  &__body {
    display flex
    flex-direction column
    gap 10px
    padding 0 10px

    &-link {
      padding 10px
      border-radius var(--main-radius)

      &:hover {
        background var(--gray)
      }
    }
  }
}
</style>
