<template>
  <div class="catalog-filter">
    <div class="catalog-filter__top">
      <span class="catalog-filter__title">Каталог</span>
    </div>
    <div class="catalog-filter__bottom">
      <template v-if="siblings && siblings.length">
        <FiltersItemComponent v-for="(item, i) in siblings" :key="i" :open="isActive(item)" :data="item" />
      </template>
      <span class="catalog-filter__empty" v-else>Пусто</span>
    </div>
  </div>
</template>

<script>
import FiltersItemComponent from "./FiltersItemComponent.vue";

export default {
  name: "CatalogFiltersComponent",
  props: {
    siblings: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isActive(item) {
      return parseInt(this.$route.params.id) === item.id;
    },
  },
  components: { FiltersItemComponent },
};
</script>

<style lang="stylus">
.catalog-filter {
  width 100%
  background var(--white)
  border: 1px solid var(--gray-dark);
  box-shadow: 0 49px 20px rgba(0, 0, 0, 0.01), 0px 28px 17px rgba(0, 0, 0, 0.03), 0px 12px 12px rgba(0, 0, 0, 0.04), 0px 3px 7px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)
  border-radius var(--big-radius)
  padding 0 0 10px
  max-height: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    border-bottom 1px solid var(--gray-dark)
    padding 15px 20px
  }

  &__bottom {
    padding 5px
    display flex
    flex-direction column
    gap: 15px
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &__title {
    font-size 1.375em
    font-weight 700
    line-height 26px
  }

  &__empty {
    padding 10px 20px
  }
}
</style>
